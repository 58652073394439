import { SaleChannelCommonSettingsEnum } from "./Enums";

export class CompanyModuleResource {
  id: number;
  name: string;
  type: string;
  typeId: number;
  hasOwnAwbManagement: boolean;
  includeOrderManagement: boolean;
  awbCountryIsoCode: string;
  isMarketplace: boolean;
  canStorno:boolean;
  isTender:boolean;
  expirationDate: Date;
  settingValues: Array<CompanyModuleSettingValueResource>;

  schedule: string;
  nextRun: Date;
  lastStarted: Date;
  lastEnded: Date;
  lastSucceeded: Date;
  enabled: boolean;

  constructor(data?) {
    data = data || {};
    this.id = data.id || null;
    this.name = data.name || null;
    this.type = data.type || null;
    this.typeId = data.typeId || null;
    this.hasOwnAwbManagement = data.hasOwnAwbManagement || false;
    this.includeOrderManagement = data.includeOrderManagement || null;
    this.isMarketplace = data.isMarketplace || false;
    this.awbCountryIsoCode = data.awbCountryIsoCode || null;
    this.expirationDate = data.expirationDate != null ? new Date(data.expirationDate) : null;
    this.enabled = data.enabled || false;
    this.schedule = data.schedule || null;
    this.nextRun = data.nextRun || new Date();
    this.canStorno = data.canStorno || false;
    this.isTender = data.isTender || false;
    this.settingValues = new Array<CompanyModuleSettingValueResource>();

    if (data.settingValues != null) {
      data.settingValues.forEach(item => {
        this.settingValues.push(new CompanyModuleSettingValueResource(item))
      });
    }
  }
}

export class CompanyModuleSettingValueResource {
  name: string;
  value: string;

  constructor(data?) {
    data = data || {};
    this.name = data.name || null;
    this.value = data.value || null;
  }

  ProductCodeIdentifierToCamelCase() {
    if (this.name == SaleChannelCommonSettingsEnum.ProductCodeIdentifier && this.value != null) {
      return this.value.charAt(0).toLocaleLowerCase() + this.value.slice(1);
    }
    else return this.value;
  }
}

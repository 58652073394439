<mat-progress-bar *ngIf="isSyncronizing" mode="indeterminate"></mat-progress-bar>
<div id="wrapper" class="body-bg">
  <div class="container-box">
    <div class="content-area">
      <div class="container-fluid">

        <div class="main" >
          <div *ngIf="!isLoadingData && !isSyncronizing" class="reports-header shadow">
            <div *ngIf="companyReportsData; else noReports">
              <h5>Rapoarte generate pe ultimele 6 luni ( {{ formatDate(lastMonths[0]) + ' - ' + formatDate(lastMonths[lastMonths.length - 1]) }} )</h5>
              <p>Ultimul raport generat {{ companyReportsData.date }}</p>
            </div>
            <ng-template #noReports>
              <h5>Nu există rapoarte</h5>
            </ng-template>
            <button
              mat-flat-button
              extended
              type="button"
              color="primary"
              style="margin-left: 10px"
              (click)="syncEcapReports()"
              class="mr-2 py-2 px-4">
              <mat-icon fontSet="fa" fontIcon="fa-refresh"></mat-icon>
              Sincronizează rapoarte
            </button>
          </div>
          <div class="sparkboxes mt-4">
            <div class="box box1">
              <div *ngIf="currentTotalSales !== null" class="details">
                <h3 style="color:#fff;font-weight: 600;">{{currentTotalSales.toFixed(2)}}</h3>
                <h4 style="color:#fff;font-weight: 600;">VÂNZĂRI</h4>
              </div>
              <!-- <div id="salesReportCard"></div> -->
              <apx-chart *ngIf="totalSalesOptions && currentTotalSales !== null"
                [series]="totalSalesOptions.series"
                [chart]="totalSalesOptions.chart"
                [xaxis]="totalSalesOptions.xaxis"
                [labels]="totalSalesOptions.labels"
                [yaxis]="totalSalesOptions.yaxis"
                [stroke]="totalSalesOptions.stroke"
                [markers]="totalSalesOptions.markers"
                [grid]="totalSalesOptions.grid"
                [colors]="totalSalesOptions.colors"
                [tooltip]="totalSalesOptions.tooltip"
              ></apx-chart>
              <div *ngIf="currentTotalSales  === null && !isLoadingData">
                <h4 style="color:#fff;font-weight: 600;">VÂNZĂRI</h4>
                <span style="color:#fff;font-weight: 600;text-align: center;">Nu există date de afișat</span>
              </div>
            </div>
            <div class="box box2">
              <div *ngIf="currentTotalOrders !== null" class="details">
                <h3 style="color:#fff;font-weight: 600;">{{currentTotalOrders}}</h3>
                <h4 style="color:#fff;font-weight: 600;">COMENZI</h4>
              </div>
              <apx-chart *ngIf="totalOrdersOptions && currentTotalOrders !== null"
                [series]="totalOrdersOptions.series"
                [chart]="totalOrdersOptions.chart"
                [xaxis]="totalOrdersOptions.xaxis"
                [labels]="totalOrdersOptions.labels"
                [yaxis]="totalOrdersOptions.yaxis"
                [stroke]="totalOrdersOptions.stroke"
                [markers]="totalOrdersOptions.markers"
                [grid]="totalOrdersOptions.grid"
                [colors]="totalOrdersOptions.colors"
                [tooltip]="totalOrdersOptions.tooltip"
              ></apx-chart>
              <div *ngIf="currentTotalOrders === null && !isLoadingData">
                <h4 style="color:#fff;font-weight: 600;">COMENZI</h4>
                <span style="color:#fff;font-weight: 600;text-align: center;">Nu există date de afișat</span>
              </div>
            </div>

            <div class="box box3">
              <div *ngIf="currentTotalAwbs !== null" class="details">
                <h3 style="color:#fff;font-weight: 600;">{{currentTotalAwbs}}</h3>
                <h4 style="color:#fff;font-weight: 600;">AWB</h4>
              </div>
              <apx-chart *ngIf="totalAwbsOptions && currentTotalAwbs !== null"
                [series]="totalAwbsOptions.series"
                [chart]="totalAwbsOptions.chart"
                [xaxis]="totalAwbsOptions.xaxis"
                [labels]="totalAwbsOptions.labels"
                [yaxis]="totalAwbsOptions.yaxis"
                [stroke]="totalAwbsOptions.stroke"
                [markers]="totalAwbsOptions.markers"
                [grid]="totalAwbsOptions.grid"
                [colors]="totalAwbsOptions.colors"
                [tooltip]="totalAwbsOptions.tooltip"
              ></apx-chart>
              <div *ngIf="currentTotalAwbs === null && !isLoadingData">
                <h4 style="color:#fff;font-weight: 600;">AWB</h4>
                <span style="color:#fff;font-weight: 600;text-align: center;">Nu există date de afișat</span>
              </div>
            </div>

            <div class="box box4">
              <div *ngIf="currentTotalProducts !== null" class="details">
                <h3 style="color:#fff;font-weight: 600;">{{currentTotalProducts}}</h3>
                <h4 style="color:#fff;font-weight: 600;">PRODUSE</h4>
              </div>
              <apx-chart *ngIf="totalProductsOptions && currentTotalProducts !== null"
                [series]="totalProductsOptions.series"
                [chart]="totalProductsOptions.chart"
                [xaxis]="totalProductsOptions.xaxis"
                [labels]="totalProductsOptions.labels"
                [yaxis]="totalProductsOptions.yaxis"
                [stroke]="totalProductsOptions.stroke"
                [markers]="totalProductsOptions.markers"
                [grid]="totalProductsOptions.grid"
                [colors]="totalProductsOptions.colors"
                [tooltip]="totalProductsOptions.tooltip"
              ></apx-chart>
              <div *ngIf="currentTotalProducts === null && !isLoadingData">
                <h4 style="color:#fff;font-weight: 600;">PRODUSE</h4>
                <span style="color:#fff;font-weight: 600;text-align: center;">Nu există date de afișat</span>
              </div>
            </div>

          </div>


          <div class="row mt-4">
            <div class="col-md-6">
              <div class="box shadow mt-4" [@fadeInOut]="isLoadingData ? 'void' : 'loaded'">
                <h4>Top Produse Vandute</h4>

                <div class="table-container" style="margin-top:2rem;">
                  <!-- Adapted PrimeNG p-table -->
                  <p-table *ngIf="companyLastSells; else emptyTopProducts" [value]="companyLastSells | slice:0:5" [loading]="isLoadingData" [tableStyle]="{'height': '385px'}">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th pSortableColumn="id" style="width: 20%">Code <p-sortIcon field="id"></p-sortIcon></th>
                        <th pSortableColumn="name" style="width: 40%">Nume <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="quantity" style="width: 40%">Cantitate <p-sortIcon field="quantity"></p-sortIcon></th>
                        <th pSortableColumn="price" style="width: 20%">Vânzări <p-sortIcon field="price"></p-sortIcon></th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-product let-columns="columns">
                      <tr>
                        <td>{{ product.id }}</td>
                        <td>{{ product.name }}</td>
                        <td>{{ product.quantity }}</td>
                        <td>{{ product.price | currency: 'RON' }}</td>
                      </tr>

                    </ng-template>
                  </p-table>

                  <ng-template #emptyTopProducts>
                    <div style="display:flex;align-items: center; justify-content: center;height: 385px;">
                      <span>Nu există produse vândute</span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box shadow mt-4" [@fadeInOut]="isLoadingData ? 'void' : 'loaded'" style="height: 485px;">
                <h4>Vânzări</h4>
                <div  class="chart-container">
                  <apx-chart *ngIf="salesPerSaleChannelReport"
                  [chart]="salesPerSaleChannelReport.chart"
                  [stroke]="salesPerSaleChannelReport.stroke"
                  [series]="salesPerSaleChannelReport.series"
                  [title]="salesPerSaleChannelReport.title"
                  [markers]="salesPerSaleChannelReport.markers"
                  [grid]="salesPerSaleChannelReport.grid"
                  [labels]="salesPerSaleChannelReport.labels"
                  [xaxis]="salesPerSaleChannelReport.xaxis"
                  [legend]="salesPerSaleChannelReport.legend"
                  [toolbar]="salesPerSaleChannelReport.toolbar"
                  [zoom]="salesPerSaleChannelReport.zoom"
                  ></apx-chart>
                </div>
                <div *ngIf="!companyReportsData?.salesPerSaleChannel && !isLoadingData">
                  <div style="display:flex;align-items: center; justify-content: center;height: 385px;">
                    <span>Nu există vânzări</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="box shadow mt-4" [@fadeInOut]="isLoadingData ? 'void' : 'loaded'">
                <h4>Comenzi</h4>
                <div class="table-container" style="margin-top:2rem;">
                  <table *ngIf="ordersOverview; else emptyOrdersData" class="table" style="height: 385px;">
                    <tbody>
                      <tr>
                        <th scope="row">Cu locker</th>
                        <td colspan="2">{{ ordersOverview.lockersNo }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Achitate card</th>
                        <td colspan="2">{{ ordersOverview.cardPaymentNo }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Achitate cash</th>
                        <td colspan="2">{{ ordersOverview.cashPaymentNo }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Achitate cu ordin plata</th>
                        <td colspan="2">{{ ordersOverview.paymentOrderNo }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Taxe livrare</th>
                        <td colspan="2">{{ ordersOverview.shippingTaxes | currency: 'RON' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Valoare discounturi oferite</th>
                        <td colspan="2">{{ ordersOverview.offeredDiscounts | currency: 'RON' }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <ng-template #emptyOrdersData>
                    <div style="display:flex;align-items: center; justify-content: center;height: 385px;">
                      <span>Nu există date</span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box shadow mt-4" [@fadeInOut]="isLoadingData ? 'void' : 'loaded'" style="height: 485px;">
                <h4>Top comenzi pe orașe</h4>
                <div class="chart-container">
                  <apx-chart *ngIf="cityDetailsReport"
                    [series]="cityDetailsReport.series"
                    [chart]="cityDetailsReport.chart"
                    [labels]="cityDetailsReport.labels"
                    [title]="cityDetailsReport.title"
                    [stroke]="cityDetailsReport.stroke"
                    [fill]="cityDetailsReport.fill"
                    
                  ></apx-chart>
                </div>
                <div *ngIf="!companyReportsData?.cityDetails && !isLoadingData" >
                  <div style="display:flex;align-items: center; justify-content: center;height: 385px;">
                    <span>Nu există comenzi plasate</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="box shadow mt-4" [@fadeInOut]="isLoadingData ? 'void' : 'loaded'" style="height: 485px;">
                <h4>AWB-uri</h4>
                <div  class="chart-container">
                  <apx-chart *ngIf="courierReports"
                    [chart]="courierReports.chart"
                    [stroke]="courierReports.stroke"
                    [series]="courierReports.series"
                    [title]="courierReports.title"
                    [markers]="courierReports.markers"
                    [grid]="courierReports.grid"
                    [labels]="courierReports.labels"
                    [xaxis]="courierReports.xaxis"
                    [legend]="courierReports.legend"
                  ></apx-chart>
                </div>
                <div *ngIf="!companyReportsData?.courierReport && !isLoadingData">
                  <div style="display:flex;align-items: center; justify-content: center;height: 385px;">
                    <span>Nu există awb-uri procesate</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box shadow mt-4" [@fadeInOut]="isLoadingData ? 'void' : 'loaded'" style="height: 485px;">
                <h4>Noutăți</h4>
                <div>
                  <img src="../../assets/working_on.gif" alt="Working on" style="display: block;margin-left: auto;margin-right: auto;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



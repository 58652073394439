export enum CompanyModules {
  Emag = 'emag',
  EmagBG = 'emagbg',
  EmagHU = 'emaghu',
  EmagFulfillment = 'emagfulfillment',
  EmagPriceDetector = 'emagpricedetector',
  Cel = 'cel',
  Olx = 'olx',
  Nop = 'nop',
  Dpd = 'dpd',
  Cargus = 'cargus',
  Postis = 'postis',
  NopProductsFastEdit = 'nopproductsfastedit',
  WinMentor = 'winmentor',
  Geko = 'geko',
  EuroMaster = 'euromaster',
  Malis = 'malis',
  Woo = 'woo',
  EmagIncome = 'emagincome',
  EmagInvoice = 'emaginvoice',
  CelInvoice = 'celinvoice',
  NopInvoice = 'nopinvoice',
  EmagAwb = 'emagawb',
  CelAwb = 'celawb',
  NopAwb = 'nopawb',
  EmagImport = 'emagimport',
  CelImport = 'celimport',
  NopImport = 'nopimport',
  Translate = 'translate',
  Invoice = 'invoice',
  KekKobra = 'kekkobra',

  PrestaShop = 'prestashop',
  PrestaShopInvoice = 'prestashopinvoice',
  PrestaShopAwb = 'prestashopawb',
  PrestaShopImport = 'prestashopimport',

  Magento = 'magento',
  MagentoInvoice = 'magentoinvoice',
  MagentoAwb = 'magentoawb',
  MagentoImport = 'magentoimport',

  Shopify = 'shopify',
  ShopifyImport = 'shopifyimport',
  ShopifyAwb = 'shopifyawb',
  ShopifyInvoice = 'shopifyinvoice',

  Opencart = 'opencart',
  OpencartImport = 'opencartimport',
  OpencartAwb = 'opencartawb',
  OpencartInvoice = 'opencartinvoice'
}

export enum CompanyRoleEnum {
  Maintainer = 1,
  Accountant = 2,
  Stock = 3,
  Registered = 4,
  Admin = 5,
  Operator = 6,
  Editor = 7,
  PriceDetector = 8,
  PriceManagement = 9,
  Agent = 10,
  Demo = 11
}

export enum ManagementModule {
  WinMentor = 'winmentor',
  SmartBill = 'smartbill'
}

export enum NopProductCode {
  Sku = 'sku',
  Gtin = 'gtin'
}

export enum LogLevel {
  Debug = 10,
  Information = 20,
  Warning = 30,
  Error = 40,
  Fatal = 50
}

export enum SupplierSettingTypeEnum {
  Auth = "auth",
  Protocol = "protocol",
  Format = "format",
  DeliveryTime='deliveryTime'
}

export enum SupplierAuthEnum {
  None = "none",
  Bearer = "bearer",
  Basic = "basic",
  OAuth = "oauth"
}

export enum SupplierFormatEnum {
  Xml = "xml",
  Json = "json",
  Csv = "csv",
  Xlsx = "xlsx",
  Xls = "xls"
}

export enum SupplierProtocolEnum {
  Https = "https",
  Api = "api",
  Ftp = "ftp",
  Upload = "upload"
}

export enum CountryIsoCodesEnum {
  Ro = "ro",
  Hu = "hu",
  Bg = "bg"
}

export enum CourierTypesEnum {
  Postis = "Postis",
  Dpd = "Dpd",
  Cargus = "Cargus",
  Sameday = "Sameday",
  FanCourier = "FanCourier",
  Dhl = "Dhl",
  Nemo = "Nemo",
  Futar = "Futar",
  InnoShip = "InnoShip",
  Gls = "Gls",
  Econt = "Econt",
  Ecolet = "Ecolet",
  DragonStar = "DragonStar",

  Curiera = "Curiera",
  CautCurier = "CautCurier",
}

export enum IncomeModuleErpSettingEnum {
  NexusImportType = "nexusImportType",
  NexusDocumentType = "nexusDocumentType",
  NexusCurrency = "nexusCurrency",
  NexusAccount = "nexusAccount",
  NexusCorrespondedAccount = "nexusCorrespondedAccount",
  NexusDocumentSeries = "nexusDocumentSeries",
  NexusInvoiceType = "nexusInvoiceType",
  MentorBankName = "mentorBankName",
  MentorBankSimbol = "mentorBankSimbol",
  MentorIbanIncome = "mentorIbanIncome",
  MentorIncomeUndefinedPartner = "mentorIncomeUndefinedPartner"
}

export enum ImportInvoiceModuleErpSettingEnum {
  NexusDocumentType = "nexusDocumentType",
  NexusCreditAccount = "nexusCreditAccount",
  NexusCifSupplier = "nexusCifSupplier",
  NexusProductType = "nexusProductType"
}

export enum CategoryMappingModuleTypeEnum {
  FeedIn = "FeedIn",
  CompanySaleChannel = "CompanySaleChannel",
  MarketplaceSaleChannelType = "MarketplaceSaleChannelType",
  FeedOut = "FeedOut"
}

export enum CtContransportModuleTypeEnum{
 Name = "CtContransport",
 Management = "api.management",
 Token = "api.token"
}

export enum Frisbo{
  ApiUsername = "api.username",
  ApiPassword = "api.password",
  OrganizationId = "api.organization.id"
 }

export enum PepitaSettingEnum {
  Name = "Pepita",
  Token = "api.token",
  SaleChannelReferenceId = "saleChannel.reference"
}

export enum NavisionSettingEnum {
  Name = "Navision",
  Token = "api.token"
}

export enum SeapSettingEnum{
  ApiUsername = "api.username",
  ApiPassword = "api.password",
  CertificateUrl = "certificate.url"
}

export enum SkroutzSettingEnum {
  Name = "Skroutz",
  Token = "api.token",
  SkroutzToken = "api.skroutz.token",
  SaleChannelReferenceId = "saleChannel.reference"
}

export enum SaleChannelTypesEnum {
  NopCommerce = "NopCommerce",
  EmagRomania = "EmagRomania",
  EmagUngaria = "EmagUngaria",
  EmagBulgaria = "EmagBulgaria",
  EmagFulfillment = "EmagFulfillment",
  Olx = "Olx",
  Cel = "Cel",
  Magento = "Magento",
  PrestaShop = "PrestaShop",
  WooCommerce = "WooCommerce",
  Shopify = "Shopify",
  Opencart = "Opencart",
  CDiscount = "CDiscount",
  Doraly = "Doraly",
  CsCart = "CsCart",
  VivreRomania = "VivreRomania",
  VivreBulgaria = "VivreBulgaria",
  VivreUngaria = "VivreUngaria",
  MerchantPro = "MerchantPro",
  BaseLinker = "BaseLinker",
  Elefant = "Elefant",
  Altex = "Altex",
  Magazin = "Magazin",
  Coestim = "Coestim",
  Okazii = "Okazii",
  Infinity = "Infinity",
  Soline = "Soline",
  Pepita = "Pepita",
  Skroutz = "Skroutz",
  B2B = "B2B",
  Compari = "Compari",
  OpencartV1 = "OpencartV1",
  Seap = "Seap",
  Ebay = "Ebay",
  Amazon = "Amazon",
  VTex = "VTex"
}

export enum ErpTypesEnum {
  Winmentor = "Winmentor",
  Smartbill = "Smartbill",
  Facturis = "Facturis",
  Fgo = "Fgo",
  Nexus = "Nexus",
  Navision = "Navision",
  BusinessCentral = "BusinessCentral"

}

export enum FulfillmentTypesEnum {
  CargoDepozit = "CargoDepozit",
  CtContransport = "CtContransport",
  B2B = "B2B",
  Fulfillment = "Fulfillment",
  FulfillmentErp = "FulfillmentErp",
  Frisbo = "Frisbo"
}


export enum TranslateModulesEnum {
  GoogleTranslate = "GoogleTranslate",
  DeepLTranslate = "DeepLTranslate",
}

export enum SaleChannelCommonSettingsEnum {
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  Currency = "currency",
  Language = "language",
  InvoiceDocumentType = "invoice.document.type"
}

export enum EmagFulfillmentCommonSettingsEnum {
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  Warehouse = "warehouse",
  Currency = "currency",
  ShippingVat = "shipping.vat"
}

export enum ErpCommonSettingsEnum {
  Management = "management"
}

export enum ProductCodeIdentifierEnum {
  Sku = "sku",
  Ean = "ean"
}

export enum NopCommerceSettingsEnum {
  ApiUrl = "api.url",
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"

}

export enum EmagRomaniaSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  AwbSenderPhone = "awb.sender.phone",
  AwbSenderAddress = "awb.sender.address",
  AwbSenderLocalityId = "awb.sender.localityid",
  AwbSenderDefaultCourierId = "awb.default.courierid",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum EmagUngariaSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum EmagBulgariaSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum OlxSettingsEnum {
  ApiClient = "api.client",
  ApiSecret = "api.secret",
  EmailAccount = "email.account",
  ContactCityId = "contact.cityid",
  ContactName = "contact.name",
  ContactPhone = "contact.phone",
  InvoiceDocumentType = "invoice.document.type"
}
export enum BaseLinkerSettingsEnum {
  ApiToken = "api.token",
  InventoryId = "api.inventory",
  ImportOrderInvoiceStatus = "order.invoice.status",
  ImportOrderInvoiceAwbStatus = "order.invoice.awb.status",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum CelSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum WinmentorSettingsEnum {
  ApiUrl = "api.url",
  CompanyName = "company.name",
  AgentName = "agent.name",
  IbanIncomes = "iban.incomes",
  Management = "management",
}

export enum SmartbillSettingsEnum {
  ApiToken = "api.token",
  ApiUser = "api.user",
  Management = "management"
}

export enum FacturisSettingsEnum {
  ApiToken = "api.token",
  ApiUser = "api.user",
  ApiPassword = "api.password",
  Management = "management"
}

export enum FgoSettingsEnum {
  ApiToken = "api.token",
  Management = "management"
}

export enum BusinessCentralSettingsEnum {
  ApiUrl = "api.url",
  TenantId = "tenant.id",
  ClientId = "client.id",
  ClientSecret = "client.secret",
  CompanyId = " company.id",
  Management = "management",
  ApiToken = "api.token"
}

export enum PostisSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ApiClientId = "api.clientid",
  SenderLocationId = "sender.locationid"
}

export enum DpdSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  SelectedCountries = "selected.countries"
}

export enum CargusSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ApiSubscriptionKey = "api.subscriptionkey",
  AwbSenderInformation = "awb.sender.information",
  ServiceTypeDefault = "service.type.default"
}

export enum EcontSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  AwbSenderInformation = "econt.sender.information"
}

export enum EcoletSettingsEnum {
  ApiUsername = "api.username",
  ApiPassword = "api.password",
  ApiClientId = "api.client.id",
  ApiClientSecret = "api.client.secret",
}

export enum FutarSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  IdOrganization = "id.organization",
  AwbSenderInformation = "futar.sender.information"
}

export enum GlsSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ClientId = "api.clientid",
  AwbSenderInformation = "gls.sender.information"
}
export enum InnoShipSettingsEnum {
apiToken = "api.token"
}

export enum DhlSettingsEnum{
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ApiNumberExport = "account.numberEx",
  ApiNumberImport = "account.numberIm",
  AwbSenderInformation = "dhl.sender.information"
}

export enum FanCourierSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  ApiClientId = "api.clientid",
  ServiceTypeDefault = "service.type.default",
  Bank = "bank",
  Iban = "iban"
}

export enum DragonStarSettingsEnum {
  ApiUser = "api.username",
  ApiPassword = "api.password",
  DefaultAaddressId = "default.addressid",
  DefaultPayment = "default.payment"
}

export enum SamedaySettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password",
  DefaultAddressId = "default.addressid"
}


export enum CautCurierSettingsEnum {
  ApiUser = "api.user",
  ApiPassword = "api.password"
}

export enum MagazinSettingsEnum {
  PalletConfigData = "pallet.config.data",
  PriceEdit = "price.edit",
  DefaultDeliveryType = "default.delivery"
}

export enum AltexSettingsEnum {
  ApiPrivateKey = "api.private.key",
  ApiPublicKey = "api.public.key",
  AwbSenderName = "awb.sender.name",
  AwbSenderContactPerson = "awb.sender.contact.person",
  AwbSenderPhone = "awb.sender.phone",
  AwbSenderCounty = "awb.sender.county",
  AwbSenderCity = "awb.sender.city",
  AwbSenderPostalCode = "awb.sender.postalcode",
  AwbSenderDefaultCourierId = "awb.default.courierid",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum CoestimSettingEnum {
  ApiPrivateKey = "api.private.key",
  ApiPublicKey = "api.public.key",
  ProductCodeIdentifier = "product.code.identifier",
  InvoiceSeries = "invoice.series",
  InvoiceDocumentType = "invoice.document.type"
}

export enum CompanyModuleTypes {
  SaleChannel = "SaleChannel",
  Courier = "Courier",
  Erp = "Erp"
}

export enum StockMethodEnum {
  FIFO = "FIFO",
  LIFO = "LIFO",
  CMP = "CMP"
}

export enum DeliveryModeEnum {
  Courier = "courier",
  Pickup = "pickup",
  PickupPersonal = "pickup.personal",
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { CategoryAttributesMappingDialogComponent } from './category-attributes-mapping-dialog/category-attributes-mapping-dialog.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LoaderComponent,
    CategoryAttributesMappingDialogComponent
  ],
  exports: [
    LoaderComponent
  ]
})
export class SharedModule { }

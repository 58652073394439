import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ProductAIProperties } from "../my-site/products/base-product-models/ProductBase";

@Injectable()
export class ArtificialInteligenceService  {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject("API_URL") apiUrl: string
  ) {
    this.apiUrl = apiUrl;
  }

  GetProductOptimised(product: ProductAIProperties) : Observable<ProductAIProperties>
  {
    return this.http.post<any>(this.apiUrl+"/api/AI/GetProductOptimised", product
    )
    .pipe(
      tap({next: (result) =>{
        //console.log("Result: " + JSON.stringify(result))
  }}),
      map((result) =>
      {
        return new ProductAIProperties(result.name, result.description, result.shortDescription, result.seName, result.metaKeyword, result.metaTitle, result.metaDescription, product.productId, product.sku, product.ean, product.language)
      }),
      catchError(this.handleError)
    )

  }
  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}

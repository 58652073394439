import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ShellComponent } from "./core/shell/shell.component";
import { extract } from "./core/i18n.service";
import { CompanyRoleEnum } from "./company/models/Enums";
import { ShellAuthorizationGuard } from "./core/authentication/shell-authorization.guard";
import { UserResolverService } from "./core/authentication/user-resolver.service";
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
  {
    path: "",
    component: ShellComponent,
    canActivate: [ShellAuthorizationGuard],
    children: [
      {
        path: "home",
        resolve: {userData: UserResolverService},
        component: HomeComponent,
      },
      {
        path:"order-details",
        resolve: {userData: UserResolverService},
        loadChildren: () => import('../app/my-site/orders/order-details/order-details.module').then(m => m.OrderDetailsModule)
      },
      {
        path: "base-products/:companySaleChannelName/:companySaleChannelId",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/my-site/products/nop-products.module").then((m) => m.NopProductsModule),
        data: {
          title: extract("Produse"),
          roles: [CompanyRoleEnum.Editor, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "products-mappings",
        resolve: {userData: UserResolverService},
        loadChildren: () =>
          import("../app/products-mappings/products-mappings.module").then((m) => m.ProductsMappingsModule),
        data: {
          title: extract("Preturi"),
          roles: [CompanyRoleEnum.Editor, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "base-orders/:companySaleChannelName/:companySaleChannelId",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/my-site/orders/orders.module").then((m) => m.BaseOrdersModule),
        data: {
          title: extract("Comenzi"),
          roles: [CompanyRoleEnum.Operator, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "base-returns/:companySaleChannelName/:companySaleChannelId",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/my-site/returns/returns.module").then((m) => m.BaseReturnsModule),
        data: {
          title: extract("Retururi"),
          roles: [CompanyRoleEnum.Operator, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "olx-login-redirect",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/olx/olx-authentication.module").then((m) => m.OlxAuthenticationModule),
        data: {
          title: extract("Olx login redirect"),
          roles: [CompanyRoleEnum.Registered, CompanyRoleEnum.Admin],
        },
      },
      {
        path: "ecap-orders",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/my-site/orders/orders-ecap/order.module").then((m) => m.EcapOrdersModule),
        data: {
          title: extract("Toate Comenzile"),
          roles: [CompanyRoleEnum.Operator, CompanyRoleEnum.Admin],
        },
      },
      {
        path: "warehouse-orders",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/my-site/warehouse-management/warehouse-management.module").then((m) => m.WarehouseManagementModule),
        data: {
          title: extract("Warehouse Management"),
          roles: [CompanyRoleEnum.Operator, CompanyRoleEnum.Admin],
        },
      },
      {
        path: "emag-fulfillment",
        resolve: {userData: UserResolverService},
        loadChildren: () =>
          import("../app/emag/fulfillmentV2/orders-fulfillment.module").then((m) => m.OrdersFulfillmentModule),
        data: {
          title: extract("Comenzi"),
          roles: [CompanyRoleEnum.Operator, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "stocks",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/stock/stock.module").then((m) => m.StockModule),
        data: {
          title: extract("Stocuri"),
          roles: [CompanyRoleEnum.Stock, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "incomes",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/incomes/incomes.module").then((m) => m.IncomesModule),
        data: {
          title: extract("Incasari"),
          roles: [CompanyRoleEnum.Accountant, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "import-invoices",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/import-invoice/import-invoice.module").then((m) => m.ImportInvoiceModule),
        data: {
          title: extract("Facturi"),
          roles: [CompanyRoleEnum.Accountant, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "feed-in/:companyFeedInName/:companyFeedInId",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/feed-in/feed-in.module").then((m) => m.FeedInModule),
        data: {
          title: extract("Feed in"),
          roles: [CompanyRoleEnum.Maintainer, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "feed-out/:companyFeedOutName/:companyFeedOutId",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/feed-out/feed-out.module").then((m) => m.FeedOutModule),
        data: {
          title: extract("Feed out"),
          roles: [CompanyRoleEnum.Maintainer, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "companies",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/company/company.module").then((m) => m.CompanyModule),
        data: {
          title: extract("Lista Firme"),
        },
      },

      {
        path: "price-detector/:priceDetectorModuleName/:priceDetectorModuleId",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/emag/price-detector/campaign/campaign.module").then((m) => m.CampaignModule),
        data: {
          title: extract("Price Detector"),
          roles: [CompanyRoleEnum.Accountant, CompanyRoleEnum.Admin, CompanyRoleEnum.Demo],
        },
      },
      {
        path: "log-summary",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/log-dashboard/log-dashboard.module").then((m) => m.LogDashboardModule),
        data: {
          title: extract("Logs"),
        },
      },
      {
        path: "log-error",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/log-error/log-error.module").then((m) => m.LogErrorModule),
        data: {
          title: extract("Erori"),
        },
      },
      {
        path: "tichete",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/DevOps/devops.module").then((m) => m.DevopsModule),
        data: {
          title: extract("Tichete"),
        },
      },
      {
        path: "legal",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/legal/legal.module").then((m) => m.LegalModule),
        data: {
          title: extract("Legal"),
        },
      },
      {
        path: "ecap-invoice",
        resolve: {userData: UserResolverService},
        loadChildren: () =>
          import("../app/ecap-clients-invoice/module/ecap-clients-invoice.module").then(
            (m) => m.EcapClientsInvoiceModule
          ),
        data: {
          title: extract("ecap-invoice"),
        },
      },
      {
        path: "ecap-invoice-management",
        resolve: {userData: UserResolverService},
        loadChildren: () =>
          import("../app/ecap-invoices-management/module/ecap-invoice-management.module").then(
            (m) => m.EcapInvoiceManagementModule
          ),
        data: {
          title: extract("ecap-invoice-management"),
        },
      },
      {
        path: "reports/invoices",
        resolve: {userData: UserResolverService},
        loadChildren: () => import("../app/reports/reports.module").then((m) => m.ReportsModule),
        data: {
          title: extract("Rapoarte"),
          roles: [CompanyRoleEnum.Operator, CompanyRoleEnum.Admin, CompanyRoleEnum.Agent, CompanyRoleEnum.Demo],
        },
      },

      { path: "**", redirectTo: "home" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}

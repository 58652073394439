import { DonutChartDto, LineChartDto } from "../../company/models/ChartDataDto";

export const optionsLine = (chartLineData: LineChartDto) => {
  return {
    chart: {
      height: 328,
      type: 'line',
      dropShadow: {
        enabled: true,
        top: 3,
        left: 2,
        blur: 4,
        opacity: 1,
      },
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: "zoom"
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    series: chartLineData.series,
    title: {
      text: '',
      align: 'left',
      offsetY: 1,
      offsetX: 20
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9
      }
    },
    grid: {
      show: true,
      padding: {
        bottom: 0
      }
    },
    labels: chartLineData.labels,
    xaxis: {
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return val.toFixed();
          }
        }
      }
    ],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: -20
    },
    
  }
};

export const polarOptions = (donutChartData: DonutChartDto) => {
  return {  
    series: donutChartData.series.map(serie => serie.data[0]),
    labels: donutChartData.series.map(serie => serie.name),
    chart: {
      type: 'polarArea',
      height: 390,
    },
    title: {
      text: '',
      align: 'left',
      offsetY: 15,
      offsetX: 20
    },
    stroke: {
      colors: ['#fff']
    },
    fill: {
      opacity: 0.8
    },
    // responsive: [{
    //   breakpoint: 480,
    //   options: {
    //     chart: {
    //       width: 200
    //     },
    //     legend: {
    //       position: 'bottom'
    //     }
    //   }
    // }],
  }
};

export const optionsBarProfit = (profitBarChartData) => ({
    series: profitBarChartData.series.map(serie => ({
      name: serie.name,
      data: serie.data
    })),
    chart: {
      type: "bar",
      height: 380,
      stacked: true,
      dropShadow: {
        enabled: true,
        blur: 1,
        opacity: 0.25
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "60%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    title: {
      text: "Vanzari per Client"
    },
    xaxis: {
      categories: profitBarChartData.labels
    },
    yaxis: {
      title: {
        text: undefined
      }
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val + "Lei";
        }
      }
    },
    fill: {
      type: "pattern",
      opacity: 1
    },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    },
    legend: {
      position: "right",
      offsetY: 40
    }
});

export const optionsBar = (barChartData) => ( {
    chart: {
      height: 380,
      type: 'bar',
      stacked: true,
    },
    title: {
      text: 'Produse',
      align: 'left',
      offsetY: 5,
      offsetX: 20
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        horizontal: false,
      },
    },
    series: barChartData.series,
    xaxis: {
      categories: barChartData.labels ,
    },
    fill: {
      opacity: 1
    },
});

export const donutOptions = (donutChartData) => ({
    series: donutChartData.series.map(serie => serie.data[0]),
    labels: donutChartData.series.map(serie => serie.name),
    title: {
      text: 'Orase',
      align: 'left',
      offsetY: 15,
      offsetX: 20
    },
    chart: {
      height: 390,
    
      type: 'pie'
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      position: 'right',
      offsetY: 0,
      offsetX: -10,
      labels: {
        colors: ['#ffffff'],
        useSeriesColors: false
      },
      formatter: function(val, opts) {
        return val + ': ' + opts.w.globals.series[opts.seriesIndex];
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom',
            offsetY: 0,
            offsetX: 0
          }
        }
      }
    ]
});

export const optionsArea  = (areachartdata) => ({

  chart: {
    height: 380,
    type: 'area',
    stacked: false,
  },
  title: {
    text: 'Clienti',
    align: 'left',
    offsetY: 5,
    offsetX: 20
  },
  stroke: {
    curve: 'straight'
  },
  
  series: areachartdata.series,
  xaxis: {
    categories: areachartdata.labels,
  },
  tooltip: {
    followCursor: true
  },
  fill: {
    opacity: 1,
  },
});

 

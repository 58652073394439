import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject, of } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { ReportsService } from "../reports/service/reports.service";
import { EcapCompanyReports, LastItemsSold, OrdersOverview } from '../company/models/ChartDataDto';
import { optionsLine } from '../home/chart-styles/chart-styles';
import { polarOptions } from './chart-styles/chart-styles';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  isLoadingData: boolean = true;
  isSyncronizing: boolean = false;

  //for last products sold
  companyLastSells: LastItemsSold[];
  companyReportsData: EcapCompanyReports;
  ordersOverview: OrdersOverview;
  currentTotalSales: number = null;
  currentTotalOrders: number = null;
  currentTotalAwbs: number = null;
  currentTotalProducts: number = null;
  lastMonths: string[];

  commonOptions: any;
  totalSalesOptions: any;
  totalOrdersOptions: any;
  totalAwbsOptions: any;
  totalProductsOptions: any;
  courierReports: any;
  cityDetailsReport: any;
  salesPerSaleChannelReport: any;

  constructor(private reportService: ReportsService) {
    this._unsubscribeAll = new Subject();

  }

  ngOnInit() {
    this.isLoadingData = true;
    this.lastMonths = this.getLastMonths();

    if(!this.reportService.ecapReportsValue){
      this.getEcapReports();
    }


    this.reportService.ecapReportsObservable
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((response: EcapCompanyReports) => {
          this.hydrateReports(response);
          this.isLoadingData = false;
        }),
        catchError((err) => {
          console.error("Error fetching reports", err);
          this.isLoadingData = false;
          return of(null);
        })
      )
      .subscribe();
  }

  hydrateReports(companyReportsData: EcapCompanyReports) {
    if (companyReportsData) {
      this.companyReportsData = companyReportsData;
      this.companyLastSells = companyReportsData.bestProductDetails;
      this.ordersOverview = companyReportsData.ordersOverview;

      this.currentTotalSales = companyReportsData.totalOrderValue?.reduce((current, next) => current + next, 0) || null;
      this.currentTotalOrders = companyReportsData.noTotalOrders?.reduce((current, next) => current + next, 0) || null;
      this.currentTotalAwbs = companyReportsData.noTotalAwb?.reduce((current, next) => current + next, 0) || null;
      this.currentTotalProducts = companyReportsData.noTotalProducts?.reduce((current, next) => current + next, 0) || null;

      this.totalSalesOptions = this.currentTotalSales !== null ? this.sparkOptions(companyReportsData.totalOrderValue, 'totalOrderValue') : null;
      this.totalOrdersOptions = this.currentTotalOrders !== null ? this.sparkOptions(companyReportsData.noTotalOrders, 'noTotalOrders') : null;
      this.totalAwbsOptions = this.currentTotalAwbs !== null ? this.sparkOptions(companyReportsData.noTotalAwb, 'noTotalAwb') : null;
      this.totalProductsOptions = this.currentTotalProducts !== null ? this.sparkOptions(companyReportsData.noTotalProducts, 'noTotalProducts') : null;

      this.courierReports = companyReportsData.courierReport ? optionsLine(companyReportsData.courierReport) : null;
      this.salesPerSaleChannelReport = companyReportsData.salesPerSaleChannel ? optionsLine(companyReportsData.salesPerSaleChannel) : null;
      this.cityDetailsReport = companyReportsData.cityDetails ? polarOptions(companyReportsData.cityDetails) : null;
    }
  }

  getEcapReports() {
    this.isLoadingData = true;

    return this.reportService.getEcapReports()
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((response: EcapCompanyReports) => {
          this.hydrateReports(response);
          this.isLoadingData = false;
          return response;
        }),
        catchError((err) => {
          console.error("Error synchronizing reports", err);
          this.isLoadingData = false;
          return of(null);
        })
      )
      .subscribe();
  }

  syncEcapReports() {
    this.isSyncronizing = true;

    return this.reportService.syncEcapReports()
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((response: EcapCompanyReports) => {
          this.hydrateReports(response);
          this.isSyncronizing = false;
          return response;
        }),
        catchError((err) => {
          console.error("Error synchronizing reports", err);
          this.isSyncronizing = false;
          return of(null);
        })
      )
      .subscribe();
  }

  sparkOptions = (data: number[], id: string) => {
    return {
      chart: {
        id: id,
        group: 'sparks',
        type: 'line',
        height: 80,
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 2,
          opacity: 0.2,
        }
      },
      series: [{
        name: 'No',
        data: data
      }],
      labels: this.getLastMonths(),
      yaxis: {
        min: 0
      },
      xaxis: {
        type: 'datetime',
      },
      stroke: {
        curve: 'smooth',
        colors: ['#fff']
      },
      markers: {
        size: 0
      },
      grid: {
        padding: {
          top: 20,
          bottom: 10,
          left: 110
        }
      },
      colors: ['#DCE6EC'],
      dataLabels: {
        enabled: false
      },
      tooltip: {
        hideEmptySeries: false,
        x: {
          format: 'MMMM',
          show: true
        },
        y: {
          title: {
            color: '#000'
          }
        }
      },
    }
  };

  getLastMonths(): string[] {
    const today = new Date();
    const last7Months = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-01`;
      last7Months.push(formattedDate);
    }

    return last7Months;
  }

  formatDate(inputDateString: string): string {
    const inputDate = new Date(inputDateString);
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-indexed
    const year = inputDate.getFullYear();

    // Ensure leading zero for day and month if they are single-digit
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
